import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import axios from "axios";
import { PatternFormat } from 'react-number-format';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-800 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input[type="checkbox"]{
    ${tw`w-10`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-secondary tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};
    

    ::placeholder {
      ${tw`text-gray-300 opacity-25 font-secondary`}
    }
    input,checkbox {
      ${tw `w-full m-20`}
    }
  }
`;

const SuccessContainer = styled.div`
  ${tw`hidden text-center p-10 sm:p-12 md:p-16 bg-primary-800 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-secondary tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-300 opacity-25 font-secondary`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 font-secondary bg-primary-200 text-white shadow-lg hocus:bg-white hocus:text-primary-800 rounded-full  tracking-wide uppercase text-sm transition duration-300 `;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

function submitForm (e){
e.preventDefault();
const name = document.getElementById('name-input').value;
const email = document.getElementById('email-input').value;
const message = document.getElementById('message-input').value;
const telephone = document.getElementById('phone-input').value;
const optin = document.getElementById('opt-in').value;

  console.log(optin);

  axios.post('https://7tm1nzgts7.execute-api.us-east-1.amazonaws.com/production/contact', {
    name: name,
    email: email,
    telephone: telephone,
    source: "contact-form",
    message: message
  })
  .then(function (response) {
    if(response.status === 200){
      window.location.href = "/thank-you";
    }
  })
  .catch(function (error) {
    console.log(error);
  });

}


export default () => {
  return (
    <Container>
      <Content>
        <FormContainer id="formContainer">
          <div tw="mx-auto max-w-4xl">
            <h2>Contact us for a free consultation</h2>
            <form onSubmit={submitForm}>
              <TwoColumn>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe"  required/>
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" required />
                  </InputContainer>
                  <InputContainer tw="flex-1">
                  <Label htmlFor="email-input">Phone Number</Label>
                    <PatternFormat id="phone-input" name="telephone" type="tel" format="+1 (###) ###-####"  mask="_" required></PatternFormat>
                  </InputContainer>

                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea id="message-input" name="message" placeholder="E.g. Details about your request" required/>
                  </InputContainer>
                </Column>
              </TwoColumn>
              <InputContainer>
              <Label htmlFor="opt-in">
              <input id="opt-in" name="opt-in" type="checkbox" class="w-0 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" required />
              By providing your phone number, you consent to receive SMS and Email communications regarding your claim assistance</Label>                    
              </InputContainer>

              <SubmitButton type="submit">Submit</SubmitButton>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
        <SuccessContainer id="successContainer">
          <h2>We've recieved your message!</h2>
          <p>Keep an eye out for our email, it will be sent from info@propertypeoplelaw.com</p>
        </SuccessContainer>
      </Content>
    </Container>
  );
};
