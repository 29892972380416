import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ThankYou from "components/thanks/Contact.js";
import ContactDetails from "components/cards/OneColContactDetails.js";

import { MapContainer } from "components/cards/MapComponent";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block`;
const Phone = tw.span`text-sm mt-0 block`;

export default () =>  {
    return (
      <AnimationRevealPage>
      <Header />
      <ThankYou/>
      <div id="google-reviews"></div>
      <Footer />
      
    </AnimationRevealPage>
    );
  
}