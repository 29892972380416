import React, {useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Features from "components/features/DashedBorderSixFeatures.js"

import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import Moment from 'react-moment';


const HeadingContainer = tw.div`text-center`;

const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;

const PostText = tw.div`flex-1 rounded-t-lg px-8` 
const PostTitle = tw.h3`text-xl my-6 font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDate = tw.div`flex-1 my-6`;
const PostImage = tw.div`h-64 bg-cover rounded-t-lg`;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;
const AuthorProfile = tw.div`pt-1 font-medium`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw`text-4xl`}
    }
    ${PostDescription} {
      ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
    }
    ${AuthorInfo} {
      ${tw`mt-8 flex items-center`}
    }
    ${AuthorName} {
      ${tw`mt-0 font-bold text-gray-700 text-sm`}
    }
  `}
`;

const query = `
{ 
  blogPostCollection
  {
    items {
      title
      slug
      description
      body
      publishDate
      heroImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
    }
  }
  
}
`;

function convertDate(date){
  var d = new Date(date);
  return d.toDateString();
}

function App() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/39shuj7k3ong/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1OUxPJdwMsjU4KBsQOAgXA6YXx90voYKTZjY8ZCuRyk",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        data.blogPostCollection.items.forEach(function(item){
        
        })
        setPage(data.blogPostCollection);

      });

  }, []);
console.log(page);
  // render the fetched Contentful data
  return (
 <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>

    <div className="App">
      <div className="page-deets">
        <h1></h1>
      </div>
      <>
      {!page ? (<span>Loading...</span>) : (
       
           <Posts>
          { page.items.length ? page.items.map((post, index) => (
            
          <PostContainer key={post.slug}>
            <Post className="group"  href={post.slug}>
            <PostImage style={{ backgroundImage:`url(${post.heroImage.url})`
            }}/>
            
            <PostText>
               <PostTitle>{post.title}</PostTitle>
                <PostDescription>{post.description}</PostDescription>
                <PostDate><Moment format="MMMM D, YYYY">{post.publishDate}</Moment></PostDate>
              </PostText>
            </Post>
          </PostContainer>
          )) : (
            <span>Loading...</span>
          )}
        </Posts>
        )}

      </>

      
    </div>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}

export default App;
