import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "FAQS",
  heading = "Any Questions ?",
  description = "",
  faqs = [
    {
      question: "My property suffered a loss. What should I do?",
      answer:
         "First and foremost, be honest. As a policyholder, being truthful in your insurance application and report of a loss is critical to your right to coverage and compensation! Your specific duties may depend on your individual policy, but most policies will require that you give prompt notice of loss, allow the insurance company to inspect the property, keep defective parts or components, prepare an inventory of damaged items, cooperate in any investigation (including an Examination Under Oath), and submit a signed Sworn Proof of Loss statement, among other duties. To maximize your recovery, we recommend that you contact your insurance company immediately upon seeing damage. Document any damage you see with photographs or videos, and capture any broken pipes, fallen tiles, water damage, affected personal property, or anything else you can document to evidence the loss. If you have documentation of what your property looked like before the loss, save that too, as it will help show the insurance company the extent of your damage. Prepare an inventory of damaged personal property to comprehensively value your financial loss. Do not throw away any damaged items, unless necessary for your safety. Obtain your own repair estimates so you know exactly how much it will cost to address your loss, and whether the insurance company is paying you a fair dollar figure. If you see that you have not been paid fairly for your loss, or your claim was unfairly denied, let The Property People step in. With absolutely zero upfront cost or out of pocket expense to you, we will pursue your claim thoughtfully and aggressively to obtain the maximum recovery."
    },
    {
      question: "What can The Property People do to help me recover under my insurance policy?",
      answer:
        "After collectively negotiating millions of dollars of property damage settlements, and litigating countless denied and underpaid claims, attorneys Danny and Nicole have significant experience disputing policy language and fighting for coverage. They will review the terms of your policy and strategize the most fair and effective method to maximize the value of your claim. As lawyers, The Property People work with public adjusters, loss consultants, expert general contractors, and expert engineers to understand the cause and origin of your damage and the cost to conduct necessary repairs. We will also examine the insurance company’s process of handling your claim to determine if the insurance company committed bad faith, which will affect your financial recovery. After investigating your damage to determine the fair and objective value of your loss, we will negotiate the highest possible settlement with your insurance company. If the insurance company does not agree to pay you what you are justly owed, we will file a formal lawsuit and seek court intervention to obtain your maximum legal recovery. Our passion for people, conversation, and the law helps us negotiate courteously with opposing counsel and obtain quick and favorable resolutions. At the same time, if your rights as a policyholder are disrespected, we have the skills and resources to litigate aggressively from the inception of a lawsuit to trial."
    },
    {
      question: "Sounds great. But what if I cannot afford an attorney?",
      answer:
        "Not only are we offering you a free consultation, but we represent you on a contingency basis. This means we do not get paid unless we get you paid first! Specifically, we can recover our fees directly from the insurance company based on the governing laws in the State of Florida. Don’t be overwhelmed by the costs associated with fighting for your claim. Filing a lawsuit against your insurance company in Florida can cost over $500.00. Additionally, there may be other costs associated with hiring contractors, engineers, or other experts necessary to move your claim forward. We pay all the costs associated with litigating your claim and only recover those costs if, and after, we recover for you first."
    },
    {
      question: "I reported my claim to the insurance company and they told me that my claim is denied. What does that mean and what can I do?",
      answer:
      "Insurance companies routinely employ unfair tactics to deprive policyholders of their fair recovery under the policy, including citing to preexisting damage, denying payment for repairs they deem unnecessary, insisting that policyholders provide nonexistent documentation, or citing to obscure policy exclusions to deny coverage for the damage even when another policy provision provides coverage. The Property People can evaluate the language of your policy and the damage you suffered to assess what you are rightfully owed and to pursue your maximum recovery. We are happy to analyze and address the process of disputing and litigating your denied property insurance claim in a free consultation, at your convenience."
    },
    {
      question: "I reported my claim to the insurance company, but they did not give me enough money to make repairs. How do I get the money I need to repair my property?",
      answer: "If the funds that you were provided by your insurance company are not sufficient to restore your property to the pre-loss condition, or you have individually obtained higher estimates to repair the damage, which exceed what you were paid by your insurance companies, we will step in to demand the amount you need. By meticulously comparing the insurance company’s estimate with other estimates reflecting the scope and price of your loss, along with other documentation of your damage, we will prepare a thorough and comprehensive demand that explains how much more money the insurance company must pay to address your loss. By supporting our demand with legal analysis, communicating consistently with the insurance company, and negotiating for your recovery from an educated standpoint, we maximize the value of your settlement.  We are happy to analyze and address the process of disputing and litigating your underpaid property insurance claim in a free consultation, at your convenience."
    },
    {question: "“Free representation” sounds too good to be true. I do not have to pay The Property People anything?",
    answer: "You really do not have to pay us! We work with you on a contingency basis, which means that you are not responsible for any initial costs and fees necessary for your recovery. We are passionate about getting your property damage fixed fairly and efficiently, and we only get paid unless you get paid first. Additionally, pursuant Florida Statutes §§627.428 and 57.041, we can often recover our legal costs and fees directly from the insurance company. The Property People know how stressful it is to experience property damage. The insurance claim and litigation processes can feel like an uphill battle without the right representation. We are committed to guiding you through this intimidating experience, and we will always put your personal and financial needs first."
  }

  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="FAQ">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
    </Container>
  );
};
