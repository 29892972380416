import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/HomeFeatures.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import Hurricane from "images/hurricane-icon.png";
import Water from "images/flood-icon.png";
import Leak from "images/water-icon.png";
import BadFaith from "images/badfaith-icon.png";
import Theft from "images/theft-icon.png";

import Fire from "images/fire-icon.png";
import Residential from "images/miami-residential.jpeg";

import Hero from "components/hero/LandingPageEmp.js"

import Testimonials from "components/testimonials/SimplePrimaryBackground.js";
import LearnMore from "components/cta/GetStarted.js";
import FAQ from "components/faqs/SingleCol.js";
import FeaturedOn from "components/features/FeaturedOn.js";


import Martindale from "images/Martindale-logo.png";
import Haute from "images/HauteLawyer.png";
import Clear from "images/ClearChannel.png";
import AAJ from "images/AAJ.png";
import Voyage from "images/voyage.png";

import Court from "images/pexels-brett-sayles-1000740.jpg";



const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Hero/>
      <FeaturedOn
        subheading={""}
        heading="Featured On"
        description=""
        cards={[
          {
            imageSrc: Martindale,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Haute,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Clear,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Voyage,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: AAJ,
            title: "",
            description: "",
            url: "https://google.com"
          },        
        ]}
        linkText=""
      />
      
      <Testimonials/>
      <FAQ/>

 	  <LearnMore/>

      <Footer />
    </AnimationRevealPage>
  );
};
