import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/HomeFeatures.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import Hurricane from "images/hurricane-icon.png";
import Water from "images/flood-icon.png";
import Leak from "images/water-icon.png";
import BadFaith from "images/badfaith-icon.png";
import Theft from "images/theft-icon.png";

import Fire from "images/fire-icon.png";
import Residential from "images/miami-residential.jpeg";

import Hero from "components/hero/LandingPageDamage.js"

import Testimonials from "components/testimonials/SimplePrimaryBackground.js";
import LearnMore from "components/cta/GetStarted.js";
import FAQ from "components/faqs/SingleCol.js";
import FeaturedOn from "components/features/FeaturedOn.js";


import Martindale from "images/Martindale-logo.png";
import Haute from "images/HauteLawyer.png";
import Clear from "images/ClearChannel.png";
import AAJ from "images/AAJ.png";
import Voyage from "images/voyage.png";

import Court from "images/pexels-brett-sayles-1000740.jpg";



const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        location="California"
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="We are here to help California Policyholders!"
        buttonRounded={false}
        primaryButtonText="Learn About Us"
        description="We will put you, our client, first. We will fight for every penny of compensation that your policy promises. We will communicate consistently and remain transparent throughout the development of a claim. We will do everything in our power to get you paid quickly and fairly."
        imageSrc={Residential}
        //imageSrc="https://image.shutterstock.com/image-photo/justice-law-conceptlawyer-workplace-laptop-600w-666472699.jpg"
      />
      <Features
        subheading={<Subheading>We Got You Covered!</Subheading>}
        heading="Property Insurance Claims"
        description="We cover many property insurance claims, including but not limited to "
        cards={[
          {
            imageSrc: Hurricane,
            title: "Hurricane or Windstorm Damage",
            description: "According to the National Oceanic and Atmospheric Administration, almost three trillion dollars...",
            url: "/claim-types#hurricane"
          },
          {
            imageSrc: Water,
            title: "Water Damage",
            description: "Whether water damage to your property has resulted from leakage after heavy rains or a burst pipe...",
            url: "/claim-types#waterdamage"
          },
          {
            imageSrc: Leak,
            title: "Plumbing Failure",
            description: "California’s rambunctious climate can have no mercy. Coupled with age, poor maintenance...",
            url: "/claim-types#plumbing"
          },
          {
            imageSrc: BadFaith,
            title: "Bad Faith",
            description: "California law requires insurance companies to honor the terms of a policy, as it is a binding agreement...",
            url: "/claim-types#badfaith"
          },
          {
            imageSrc: Theft,
            title: "Theft or Vandalism",
            description: "Despite how many decent and wholesome people we see in this world, the unfortunate reality is that bad...",
            url: "/claim-types#theft"
          },
          {
            imageSrc: Fire,
            title: "Fire",
            description: "A most virulent force of nature, fire sparked by an electrical failure, gas leak, barbeque, candle spill...",
            url: "/claim-types#firedamage"
          },          
        ]}
        linkText=""
      />
      <Testimonials/>
      <FAQ/>

 	  <LearnMore/>
<FeaturedOn
        subheading={""}
        heading="Featured On"
        description=""
        cards={[
          {
            imageSrc: Martindale,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Haute,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Clear,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: Voyage,
            title: "",
            description: "",
            url: "https://google.com"
          },
          {
            imageSrc: AAJ,
            title: "",
            description: "",
            url: "https://google.com"
          },        
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
