import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import HeroImage from "../../images/partner-hero-landing.png";
import ContactUsForm from "components/forms/LandingPageContact.js";
import Checkmark from "../../images/checkmark.png";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${HeroImage});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-secondary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-2xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-0 mt-0 md:pt-24 md:pb-0 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex sm:mt-5 md:-mt-32 lg:-mt-32 flex-col items-center lg:block`;
const RightColumn = tw.div`flex flex-col items-end w-full items-end lg:block`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left md:text-2xl sm:text-4xl lg:text-4xl xl:text-4xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const List = styled.ul`
${tw`text-sm text-left lg:text-left md:text-base sm:text-sm lg:text-base xl:text-lg font-black text-gray-100 leading-none`}
li {
  ${tw`mt-3`}
}
img{ display:inline

}
`

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-blue-900 transform -skew-x-12 -z-10`}
  }
`;

const SubHead = styled.span`
  ${tw`text-sm text-left lg:text-left md:text-base sm:text-sm lg:text-base xl:text-lg relative text-white px-4 -mx-4 py-2`}
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-primary-100 text-gray-100 font-secondary rounded shadow transition duration-300 hocus:bg-primary-800 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const PropertyDamageComponentHero = ({ location }) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="https://propertypeoplelaw.com/">
        Property People
      </NavLink>
      <NavLink href="/about">
        About Us
      </NavLink>
      <NavLink href="/claim-types">
        Claim Types
      </NavLink>
      <NavLink href="/#FAQ">
        FAQs
      </NavLink>          
       <NavLink href="/contact">
        Contact Us
      </NavLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
             
            <Heading>
              <span>Your {location} Property Damage Lawyers</span>
              <br />
              <SubHead>Have you experienced residential or commercial property damage from Hurricane Helene? <br/> <br/> We're here to help.</SubHead>
            </Heading>
            <List>
                
            <ul>
                
            <li> <img src={Checkmark}></img>Flood Damage</li>
            <li><img src={Checkmark}></img>Storm Damage</li>
            <li><img src={Checkmark}></img>Water Leaks</li>
            <li><img src={Checkmark}></img>Wind Failures</li>
            <li><img src={Checkmark}></img>Hurricane Damage</li>
                
            </ul>

            </List>
            
          </LeftColumn>
          <RightColumn>
            <ContactUsForm />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

export default PropertyDamageComponentHero;