import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import ContactDetails from "components/cards/OneColContactDetails.js";

import { MapContainer } from "components/cards/MapComponent";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block`;
const Phone = tw.span`text-sm mt-0 block`;

export default () =>  {
    return (
      <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Miami, Florida",
            description: (
              <>
                <Address>
                  <AddressLine>80 SW 8th St, Suite 2590</AddressLine>
                  <AddressLine>Miami, FL 33130</AddressLine>
                </Address>
                <Email><a href="mailto:info@propertypeoplelaw.com">info@propertypeoplelaw.com</a></Email>
                <Phone><a href="tel:(844)-776-7364">+1 (844)-776-7364</a></Phone>
              </>
            )
          },
          {
            title: "",
            description: (
              <>
                < MapContainer />
              </>
            )
          }
        ]}
      />
      <div id="google-reviews"></div>
      <Footer />
      
    </AnimationRevealPage>
    );
  
}