import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
import Pin from "../../images/map-pin.png";
const AnyReactComponent = ({ text }) => (
  <div style={{
    color: 'white',
    padding: '15px 15px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    
  </div>
);

const Marker = props => {
  return <div className="SuperAwesomePin"></div>
}


export class MapContainer extends Component {
  static defaultProps = {
    center: {
      lat: 25.765990,
      lng: -80.194470
    },
    zoom: 15
  };

 

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '20rem', width: '20rem' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBzR2EkOVYUl4YGOgOzJ7Zj7ANG1xIvgVw" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={this.props.center.lat}
            lng={this.props.center.lng}
            
          />

          <Marker lat={this.props.center.lat} lng={this.props.center.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default MapContainer;
