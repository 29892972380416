import React, {useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
const HeadingContainer = tw.div``

const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 p-4 leading-loose`}
  }
  h1 {a
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-10 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const query = `
query {
  privacyPolicyCollection {
    items {
      body {
        json
      }
    }
  }
} 
`;

function App() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/39shuj7k3ong?order=fields.name&content_type=cat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1OUxPJdwMsjU4KBsQOAgXA6YXx90voYKTZjY8ZCuRyk",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        console.log(data.privacyPolicyCollection.items[0].body.json)
        if (errors) {
          console.error(errors);
        }
        // data.privacyPolicyCollection.items[0].body.json.forEach(function(item){
        //   // console.log("item", item.content[0].value)
         
        // })
        setPage(data.privacyPolicyCollection.items[0].body.json);
       
      });

  }, []);

  // render the fetched Contentful data

  const heading = "Privacy Policy"
  const subheading = ""
  const description = ""
  const content = Array.isArray(page?.content) ? page.content : [];
  return (
    <AnimationRevealPage>
      <Header/>
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Text>

  {
    content.map((content, index) => {
        if (content.nodeType === "paragraph") {
            return (
                <p key={index}>
                    {content.content.map((textBlock) => {
                        if (textBlock.marks && textBlock.marks.some(mark => mark.type === "bold")) {
                            return <strong key={textBlock.value}>{textBlock.value}</strong>;
                        }
                        if (textBlock.marks && textBlock.marks.some(mark => mark.type === "italic")) {
                            return <em key={textBlock.value}>{textBlock.value}</em>;
                        }
                        return textBlock.value;
                    })}
                </p>
            );
        } else if (content.nodeType === "unordered-list") {
            return (
                <ul key={index}>
                    {content.content.map((listItem, listIndex) => (
                        <li key={listIndex}>
                            {listItem.content.map((listContent, listContentIndex) => {
                                if (listContent.nodeType === "paragraph") {
                                    return listContent.content.map((textBlock) => {
                                        if (textBlock.marks && textBlock.marks.some(mark => mark.type === "bold")) {
                                            return <strong key={textBlock.value}>{textBlock.value}</strong>;
                                        }
                                        if (textBlock.marks && textBlock.marks.some(mark => mark.type === "italic")) {
                                            return <em key={textBlock.value}>{textBlock.value}</em>;
                                        }
                                        return textBlock.value;
                                    });
                                }
                            })}
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    })
    
  }
      </Text>
      </ContentWithPaddingXl>
    </Container>
    <Footer />
    </AnimationRevealPage>
  );
};


export default App;