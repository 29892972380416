import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import Danny from "../../images/Danny.jpeg";
import Nicole from "../../images/Nicole.jpeg";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-secondary-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;





const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:Nicole,
      subtitle: "",
      title: "Nicole S. Houman, Esq.",
      description:"Nicole is a Southern California native, licensed to practice law in both Florida and California. Nicole earned her bachelors degree in English and Middle Eastern Studies at the University of California, Los Angeles. After graduating from the USC Gould School of Law in Los Angeles and beginning her legal career practicing business and real estate litigation in LA, she moved to Florida to join her husband, a surgical resident at the University of Miami. Nicole found her calling in property insurance law by learning and disputing restrictive policy interpretations. Outside of the law, she practices mega former pilates and loves to be outdoors. Nicole is well-versed in the legal disputes inherent in property insurance claims, and has litigated issues related to coverage findings of no peril-created openings, existing damage, constant or repeated seepage or leakage of water, neglect, wear and tear, faulty repairs, and inadequate maintenance, among many others. In preparing appellate briefs in the Third and Fourth District Courts of Appeal in Florida, Nicole has become intimately acquainted with controlling Florida case law related to property claim and property damage insurance issues. She hopes to use that experience to meaningfully and personally connect with you, her client. In learning about you and your property, she can carefully and thoroughly analyze the facts and apply the law to optimize the resolution of your unique property insurance claim.",
      url: "https://www.linkedin.com/in/nicole-houman-a98b1889/"
    },

    {
      imageSrc: Danny,
      subtitle: "",
      title: "Daniel M. Ilani, Esq.",
      description:
        "Danny is a horse-farm raised Chicago native. When it comes to construction and property repairs, having been raised by two experienced general contractors, Danny truly feels at home. Originally from Elgin, Illinois, Danny earned a triple-major in History, History of Science, and Sociology at the University of Wisconsin – Madison and later moved to Florida to attend law school at the University of Miami School of Law. He is currently barred in both Florida and Illinois. In his free time, he is active in his local Jewish community and an avid fan of the arts. Danny is a litigator at heart, who thrives while learning about people in depositions and at property inspections, and expressing himself in oral argument at court. He balances a litigation style that is equal parts aggressive, courteous, and honest, ensuring he conveys his Midwestern cordiality in all aspects of life. Danny has successfully litigated claims throughout South Florida in Miami-Dade, Broward, West Palm Beach, Monroe, Lee, Martin, Hillsborough counties, amongst others. He has managed attorneys handling hundreds of insurance claims for one of Florida’s largest insurance carriers, and personally negotiated over a million dollars worth of property insurance claim settlements.",
      url: "https://www.linkedin.com/in/danielilani/"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About Us</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link target="_blank" href={card.url}>See LinkedIn</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
