import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";


import HurricaneDamage from "../../images/hurricane-damage.jpeg";
import WaterDamage from "../../images/water-damage.jpeg";
import RoofLeak from "../../images/roof-leak.jpeg";
import FloorDamage from "../../images/floor-damage.jpeg";
import SinkHole from "../../images/sink-hole.jpeg";
import FireDamage from "../../images/fire-damage.jpeg";
import TheftDamage from "../../images/theft-damage.jpeg";
import Corona from "../../images/closed-corona.jpeg";
import Plumbing from "../../images/plumbing.jpeg";
import Badfaith from "../../images/badfaith.jpeg";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-primary-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;




const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        HurricaneDamage,
      subtitle: "",
      title: "Hurricane or Windstorm Damage",
      description:
        "According to the National Oceanic and Atmospheric Administration, almost three trillion dollars worth of real estate on the coast of Florida is vulnerable to hurricane damage. We have seen the catastrophic harm that property owners endure at the hands of natural hurricane forces. We also know the blood, sweat, tears, and funds that went into acquiring precious property before a relentless storm hits. The monstrous winds to torrential rains of a hurricane may cause damage ranging from a cracked window to a completely demolished structure. In any event, hurricane insurance should protect you. You agree to a higher deductible in exchange for the assurance of knowing your property damage will be covered. Contemplating the huge payouts to adjust the high volume of incoming claims after a hurricane, insurance companies can delay adjustment or payment of your claim. They can also search for any conceivable reason to deny payment. Do not fall victim to these tactics—allow us to step in and fight for the repairs and restoration you deserve, so you can focus on the health and happiness of your loved ones after a traumatic storm passes.",
      url: "",
      id: "hurricane"
    },

    // {
    //   imageSrc:
    //     Corona,
    //   subtitle: "",
    //   title: "COVID-19",
    //   description:
    //     "This is a new and emerging field of property damage law, where the scope or existence of coverage will depend on your policy language, The Property People are eager to examine your policy and seek coverage for any losses you may have sustained due to any damage or lost income that result from the COVID-19 pandemic and ongoing civil regulations – from cancelled AirBnb reservations to lost customers and reduced profits. Florida courts have not settled upon a uniform rule for when insured property has suffered a “physical loss.” But, other courts have held that contamination and other incidents that render property uninhabitable or otherwise unfit for its intended use constitutes a “physical loss” sufficient to trigger coverage. Additionally, some commercial property insurance policies provide coverage for business income losses sustained when a “civil authority” prohibits or impairs access to the policyholder’s premises. Whether a covered loss exists will therefore require a close examination of your policy and the facts of your claim.",
    //   url: ""
    // },
    {
      imageSrc:
        WaterDamage,
      subtitle: "",
      title: "Water Damage",
      description:
        "Whether water damage to your property has resulted from leakage after heavy rains or a burst pipe, water damage can leave unsightly stains and dark discoloration to your walls, ceilings, and floors. Worse, it could pose a serious threat to your health if festering water leads to a bacterial development and mold and fungi infiltrate. Insurance companies will often look for loopholes in the terms and conditions to justify a complete denial on these claims, or state that only parts of the potentially grotesque damage to your property actually resulted from the “covered loss” to justify a low-ball payment. We will take action quickly when we see the presence of water damage, which can have potentially serious consequences if left unrepaired, so that you can walk into a clean property after a long day and breathe easily.",
      url: "",
      id : "waterdamage"
    },

    {
      imageSrc:
       RoofLeak,
      subtitle: "",
      title: "Roof Leaks",
      description:
        "Florida’s rambunctious climate can have no mercy. Coupled with age, poor maintenance, dislocated tiles, and seasonal winds and storms – roofs in this State are poised for damage. Anything from a tiny trickle of water to a gaping hole could mean that your house needs an entirely new roof. Do not let the inconvenience of addressing a minor leak prevent you from taking action to prevent a major problem. The insurance company may claim that your roof only needs “patch” repairs to limited areas, undervalue the cost to solve a problematic leak, or altogether deny your claim for age-related wear and tear or deterioration. We will assist you in evaluating the fair and objective value of the damage, and what it would take to restore your roof. We will also examine your policy to see if you are owed additional compensation for interior or structural damage, or living expenses if you need to move out of your home while repairs take place. Let us address any deficiencies in your roofing system before they escalate and wreck havoc to the walls and ceilings of your property. Let us help you feel secure beneath a dependable roof fast.",
      url: "",
      id: "roofleaks"
    },

    {
      imageSrc:
       SinkHole,
      subtitle: "",
      title: "Sink Hole",
      description:
        "Florida’s terrain is largely limestone, and we experience the most sinkhole damage of any other state in America. When pressure results from an extreme drought or rain, limestone settled near the earth’s surface cause sinkholes to development. Natural erosion can cause sudden and unexpected damage to your property, and insurance companies will often try to blame human activity or reference ambiguous policy exclusions to deny the claim. We will work with geologic experts to examine the cause and origin of your damage, and ensure that you are compensated to the full extent that your policy provides, so that neither nature nor an insurer can prevent your peaceful possession of property.",
      url: "",
      id: "sinkhole"
    },
    {
      imageSrc:
       FloorDamage,
      subtitle: "",
      title: "Tile, Wood, Marble, or Laminate Floor Damage",
      description:
        "Countless people tread on your floors, and your floors are exposed to dirt, mud, and grime. But when a burst pipe, leaking roof, shifting foundation, fire, or mold contamination causes damage to even a small piece of your valuable flooring, your entire home may need to have completely new floors installed based on continuity, to restore quality and uniformity to your property. Whether you painstakingly selected gorgeous hardwood or pristine marble, the materials can be impossible to match when even minor damage occurs. Do not allow the insurance company to wrongfully deny or force you into a piecemeal repair that sacrifices the aesthetics and value of your property. Before the insurance companies steps all over your floors and your rights, we will step in and work hard to save both.",
      url: "",
      id: "floordamage"
    },
    {
      imageSrc:
       Plumbing,
      subtitle: "",
      title: "Plumbing Failures",
      description:
        "Florida properties can be centuries or months old, and the true age and quality of a plumbing system is often mysterious and confusing. Pipes are commonly made of cast iron, galvanized iron, wrought iron, or steel—but they are all susceptible to bursts or backups. Toilets and shower pans can overflow, and drain lines can unexpectedly fail, unleashing gallons of clean or sewage water into your property.  You may be left unable to use an entire bathroom, shower, or sink. Whether you notice water coming from your floors, drains, washing machine, dishwasher, toilets, or showers – you need to take action to prevent mold growth and curb ghastly odors before your property is uninhabitable. A plumbing failure can obligate your insurance company to pay for a complete replacement of your pipes, through extensive trenching that may force you to replace your flooring and baseboards, among other interior damages. We will be there to evaluate whether your insurance company is contractually required to restore your plumbing system or interior damages. We will fight on your behalf, so that your safety and sanity are promptly restored too.",
      url: "",
      id: "plumbing"
    },
    {
      imageSrc:
       Badfaith,
      subtitle: "",
      title: "Bad Faith",
      description:
        "Florida law requires insurance companies to honor the terms of a policy, as it is a binding agreement. You pay valuable premiums in exchange for a promise to be paid fairly for unexpected damage. If the insurance company delays, denies, or undervalues your claim, they may be violating their legal obligations and adjusting your claim in bad faith. Civil Remedy Notices, which are filed with the Florida Department of Financial Services, force the insurance company to examine their claims handling and consider fixing any improper adjustments or errors with a monetary “cure.” Our experience with Civil Remedy Notices may help you recover before or alongside the filing of a lawsuit. We will advise your insurance company of where they went wrong and how they need to fix it, so you can find peace.",
      url: "",
      id: "badfaith"
    },
    {
      imageSrc:
       FireDamage,
      subtitle: "",
      title: "Fire Damage",
      description:
        "A most virulent force of nature, fire sparked by an electrical failure, gas leak, barbeque, candle spill, arson or other natural cause can have extensive consequences. Water or monoxide damage following firefighting efforts can ruin your property, requiring vast repairs or replacements to the interior and exterior of your structure. We have seen fires strike in kitchens, garages, and bedrooms, and we know how traumatic a single flame can become. Let us extinguish the smoke and stress that follow a fire in your property, and advocate on your behalf when an insurance company does not pay what you need to repair the damage.",
      url: "",
      id: "firedamage"
    },
    {
      imageSrc:
       TheftDamage,
      subtitle: "",
      title: "Theft or Vandalism",
      description:
        "Despite how many decent and wholesome people we see in this world, the unfortunate reality is that bad actors will rummage around properties and infiltrate sacred space to steal or vandalize. Graffiti, keyed vehicles, and wholesale theft of valuable personal property frequently occur in Florida, and your insurance policy may provide for comprehensive coverage after such an invasion. We can review your policy to determine how you are entitled to be justly and fairly compensated. No payment from an insurance company can restore the comfort and security you felt before theft or vandalism attacked your property, but we will fight as hard as we can to make you feel whole again.",
      url: "",
      id: "theft"
    }    
  ];



  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Claim Types</HeadingTitle>
          <HeadingDescription>
            Property Insurance Claims
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card id={card.id} key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
