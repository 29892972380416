import React, {useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import AboutHeroImage from "images/AboutHero.jpeg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);


const query = `
query {
  aboutCollection(limit: 1) {
    items {
      heroText
      casesSettled
      recoveredForPolicyholders
    }
  }
}
`;

function App() {
  const [aboutHero, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/39shuj7k3ong?order=fields.name&content_type=cat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1OUxPJdwMsjU4KBsQOAgXA6YXx90voYKTZjY8ZCuRyk",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        console.log(data)
        if (errors) {
          // console.error(errors);
        }
        
        setPage(data.aboutCollection.items[0]);
      //  console.log(data.aboutCollection.items[0])
        
      });

  }, []);


  const subheading = "Our Track Record",
  heading = (
    <>
      We have been helping Florida policyholders <wbr /> since <span tw="text-primary-500">2020.</span>
    </>),
  description = "The Property People are second-generation lawyers, whose immigrant parents moved to America and became homeowners in pursuit of the American Dream. They witnessed, first-hand, the hard work required to own property. As lawyers, their mission is to protect the personal and financial investment of property ownership. They founded The Property People after working at Florida’s most prestigious insurance defense law firm. As former defense attorneys, they witnessed insurance companies routinely underpay claims and find any basis to deny coverage. Worse, they were infuriated by the unethical practices of the other attorneys they saw representing property owners, who would frequently fabricate evidence, hide settlement offers, and skirt the rules to run up their fees and avoid effectively settling claims—all at the property owner’s expense.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "/contact",
  imageSrc = AboutHeroImage,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false



  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {!aboutHero ? (<span>Loading...</span>) : (
            <Description>{aboutHero.heroText}</Description>
            )}
            <Statistics>
             
            {!aboutHero ? (<span>Loading...</span>) : (
            <Statistic >
                <Value>{aboutHero.casesSettled}</Value>
                <Key>Cases Settled</Key>
            </Statistic>
            
            )}
               {!aboutHero ? (<span>Loading...</span>) : (
            <Statistic >
                <Value>{aboutHero.recoveredForPolicyholders}</Value>
                <Key>Recovered for Policyholders</Key>
            </Statistic>
            
            )}
            </Statistics>
            
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
export default App;